import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class MaquinasService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listMachines (filtro, idArea) {
		const response = await this.axios.get("machines", {
			params: { filtro, idArea: idArea ? idArea.join(",") : null }
		});
		return response.data;
	}

	async setIdentificador (payload) {
		const resposta = await this.axios.post("machines", payload);
		return resposta;
	}

	async getSummary (idMaquinas) {
		const response = await this.axios.get("machines/summary", {
			params: { idMaquinas: idMaquinas.join(",") }
		});
		return response.data;
	}

	async getDashboardData (idArea, dataTurno) {
		const response = await this.axios.get("machines/dashboard/data", {
			params: { idArea, dataTurno }
		});
		return response.data;
	}
}
